<template>
  <v-row justify="center">
    <v-dialog v-model="localModal.show" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline background mb-4">{{ localModal.title }}</v-card-title>
        <v-card-text>
          <app-form
            ref="form"
            :fields="localModal.fields"
            :submitButton="localModal.submitButton"
            :cancelButton="{ label: 'Cancel' }"
            @submitted="handleSubmit"
            @canceled="handleCancel"
            @selection-changed="selectionChanged"
            @date-changed="dateChanged"
            @time-changed="timeChanged"
            @file-attached="handleAttach"
            @text-changed="textChanged"
            :resetForm="localModal.resetForm"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localModal: { ...this.modal }
    };
  },
  watch: {
    modal: {
      handler(newVal) {
        this.localModal = { ...newVal };
      },
      deep: true
    }
  },
  methods: {
    handleSubmit(data) {
      this.$emit('submit-modal', data);
    },
    handleCancel() {
      this.$emit('cancel-modal');
    },
    selectionChanged(data) {
      this.$emit('selection-changed', data);
    },
    dateChanged(data) {
      this.$emit('date-changed', data);
    },
    timeChanged(data) {
      this.$emit('time-changed', data);
    },
    handleAttach(data) {
      this.$emit('file-attached', data);
    },
    textChanged(data) {
      this.$emit('text-changed', data);
    }
  }
};
</script>
