import { List } from "models/list";
import { Leave } from "models/leave";

const state = {
  leaves: new List(),
};
const getters = {
  leaves: (state) => state.leaves,
};
const actions = {
  async getLeaves({ commit }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get("/leave_types");

      if (response.status === 200 && response.data.items) {
        commit("setLeaves", response.data);
      } else {
        console.error("❌ Unexpected API response structure:", response);
      }
    } catch (error) {
      console.error("❌ API Error:", error);
    } finally {
      commit("setLoading", false);
    }
  },
  async createLeave({ dispatch }, leaveData) {
    try {
      const response = await this.$app.$http.post("/leave_types", leaveData);
      if (response.status === 201) {
        await dispatch("getLeaves");
        this.$app.$snotify.success("Leave created successfully");
        return true; // ✅ Ensure it returns success
      }
    } catch (error) {
      this.$app.$snotify.error("Error creating leave");
      return false; // ✅ Return failure
    }
  },

  async updateLeave({ dispatch }, { leaveID, leaveData }) {
    try {
      const response = await this.$app.$http.put(
        `/leave_types/${leaveID}`,
        leaveData
      );
      if (response.status === 200) {
        await dispatch("getLeaves");
        this.$app.$snotify.success("Leave updated successfully");
        return true; // ✅ Return success
      }
    } catch (error) {
      this.$app.$snotify.error("Error updating leave");
      return false; // ✅ Return failure
    }
  },
  async deleteLeave({ dispatch }, leaveID) {
    try {
      const response = await this.$app.$http.delete(`/leave_types/${leaveID}`);

      if (response.status === 200 || response.status === 204) {
        await dispatch("getLeaves"); // Refresh list after delete
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
};
const mutations = {
  setLeaves: (state, data) => {
    state.leaves = {
      items: data.items.map((leave) => new Leave(leave)), // ✅ Fix: Ensure correct mapping
      count: data.items.length,
    };
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
