// import {permissionsList} from 'constants';

const state = {
  mainMenu: [
    {
      title: "layout.mainMenu.home",
      icon: "mdi-home",
      link: {
        name: "mainPage",
      },
    },
    // {
    //   title: "layout.mainMenu.leaves",
    //   icon: "mdi-beach",
    //   link: {},
    //   items: [
    //     {
    //       title: "layout.accountNavMenu.requestNewLeave",
    //       icon: "mdi-plus",
    //       link: {
    //         name: "requestLeaves",
    //         requireHRAuth: true,
    //         requireHEADAuth: true,
    //         requireTLAuth: true,
    //         requireAUDITORAuth: true,
    //       },
    //     },
    //     {
    //       title: "layout.mainMenu.companyLeaves",
    //       icon: "mdi-account-group",
    //       link: {
    //         name: "userLeaves",
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.manageLeaves",
    //       icon: "mdi-account-question",
    //       link: {
    //         name: "manageLeaves",
    //         requireCEOAuth: true,
    //         requireHRAuth: true,
    //         requireHEADAuth: true,
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: "layout.mainMenu.target",
    //   icon: "mdi-bullseye",
    //   link: {
    //     name: "target",
    //   },
    // },
    // {
    //   title: "layout.mainMenu.employees",
    //   icon: "mdi-account-group",
    //   link: {},
    //   items: [
    //     {
    //       title: "layout.accountNavMenu.adjustTeams",
    //       icon: "mdi-account-group",
    //       link: {
    //         name: "adjustTeams",
    //         requireCOLDHEADAuth: true,
    //         requireHRAuth: true,
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.addEmployee",
    //       icon: "mdi-plus",
    //       link: {
    //         name: "addEmployee",
    //         requireCEOAuth: true,
    //         requireHRAuth: true,
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.salaries",
    //       icon: "mdi-cash-multiple",
    //       link: {
    //         name: "salaries",
    //         requireCEOAuth: true,
    //         requireHRAuth: true,
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.leads",
    //       icon: "mdi-magnet-on",
    //       link: {
    //         name: "leads",
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.bonus",
    //       icon: "mdi-cash",
    //       link: {
    //         name: "bonus",
    //         requireCEOAuth: true,
    //         requireHRAuth: true,
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.kpis",
    //       icon: "mdi-cash",
    //       link: {
    //         name: "kpis",
    //         requireCEOAuth: true,
    //         requireHRAuth: true,
    //         requireHEADAuth: true,
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.removeEmployee",
    //       icon: "mdi-minus-circle-outline",
    //       link: {
    //         name: "removeEmployee",
    //         requireCEOAuth: true,
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.dailyHours",
    //       icon: "mdi-clock-time-four",
    //       link: {
    //         name: "dailyHours",
    //         requireTLAuth: true,
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.viewWorkedHours",
    //       icon: "mdi-magnify-plus-outline",
    //       link: {
    //         name: "workedHours",
    //         requireTLAuth: true,
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: "layout.mainMenu.actions",
    //   icon: "mdi-cash-remove",
    //   link: {},
    //   items: [
    //     {
    //       title: "layout.accountNavMenu.addAction",
    //       icon: "mdi-plus",
    //       link: {
    //         name: "addActionPolicy",
    //         requireCEOAuth: true,
    //         requireHRAuth: true,
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.actionsTaken",
    //       icon: "mdi-cash-remove",
    //       link: {
    //         name: "actionsTaken",
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.manageActions",
    //       icon: "mdi-clipboard-text",
    //       link: {
    //         name: "manageActions",
    //         requireCEOAuth: true,
    //         requireHRAuth: true,
    //         requireAUDITORAuth: true,
    //       },
    //     },
    //     {
    //       title: "layout.accountNavMenu.newActionAgainst",
    //       icon: "mdi-plus",
    //       link: {
    //         name: "newActionAgainst",
    //         requireCEOAuth: true,
    //         requireTLAuth: true,
    //         requireHEADAuth: true,
    //         requireAUDITORAuth: true,
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: "layout.mainMenu.holidays",
    //   icon: "mdi-beach",
    //   link: {
    //     name: "holiday",
    //     requireCEOAuth: true,
    //     requireHRAuth: true,
    //   },
    // },
    {
      title: "layout.mainMenu.candidates",
      icon: "mdi-account-group",
      link: {},
      items: [
        {
          title: "layout.accountNavMenu.addCandidate",
          icon: "mdi-plus",
          link: {
            name: "addCandidate",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },
        {
          title: "layout.accountNavMenu.viewCandidates",
          icon: "mdi-account-group",
          link: {
            name: "viewCandidates",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },
        {
          title: "layout.accountNavMenu.intervieweesTracker",
          icon: "mdi-account-group",
          link: {
            name: "intervieweesTracker",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },
        {
          title: "layout.accountNavMenu.passedInterviewsTracker",
          icon: "mdi-account-group",
          link: {
            name: "passedInterviewsTracker",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },
        {
          title: "layout.accountNavMenu.onboardingTracker",
          icon: "mdi-clipboard-account-outline",
          link: {
            name: "onboardingTracker",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },
      ],
    },
    {
      title: "layout.mainMenu.headcount",
      icon: "mdi-account-group",
      link: {},
      items: [
        {
          title: "layout.accountNavMenu.addEmployee",
          icon: "mdi-plus",
          link: {
            name: "addHeadcountEmployee",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },
        {
          title: "layout.accountNavMenu.headcountTracker",
          icon: "mdi-account-group",
          link: {
            name: "headcountTracker",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },

        {
          title: "layout.accountNavMenu.manageDepartments",
          icon: "mdi-family-tree",
          link: {
            name: "manageDepartments",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },
        {
          title: "layout.accountNavMenu.manageRoles",
          icon: "mdi-clipboard-account-outline",
          link: {
            name: "manageRoles",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },
        {
          title: "layout.accountNavMenu.manageEmploymentStatuses",
          icon: "mdi-list-status",
          link: {
            name: "manageEmploymentStatuses",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },
      ],
    },
    {
      title: "layout.mainMenu.leaveRequests",
      icon: "mdi-account-group",
      link: {},
      items: [
        {
          title: "layout.accountNavMenu.addLeaveRequest",
          icon: "mdi-plus",
          link: {
            name: "addLeaveRequest",
            requireHRAuth: false,
          },
        },
        {
          title: "layout.accountNavMenu.leaveRequestsTracker",
          icon: "mdi-clipboard-account-outline",
          link: {
            name: "leaveRequestsTracker",
            requireHRAuth: false,
          },
        },

        {
          title: "layout.accountNavMenu.leaveTypes",
          icon: "mdi-family-tree",
          link: {
            name: "leaveTypes",
            requireHRAuth: true,
            requireCEOAuth: true,
          },
        },
      ],
    },
    {
      title: "layout.mainMenu.accessTracker",
      icon: "mdi-lock-check",
      link: { requiresAdminAuth: true },
      items: [
        {
          title: "layout.accountNavMenu.grantAccess",
          icon: "mdi-plus",
          link: {
            name: "grantAccess",
            requiresAdminAuth: true,
          },
        },
        {
          title: "layout.mainMenu.accessTracker",
          icon: "mdi-lock-check",
          link: {
            name: "accessTracker",
            requiresAdminAuth: true,
          },
        },
      ],
    },
    {
      title: "layout.mainMenu.profile",
      icon: "mdi-account-tie",
      link: {
        name: "myProfile",
      },
    },
  ],
};

const getters = {
  mainMenu: (state) => state.mainMenu,
};

export default {
  state,
  getters,
};
