import { List, ListParams } from "models/list";
import { Employee } from "models/employee";

const state = {
  employees: new List(),
  managers: new List(),
  agents: new List(),
  activeAgents: new List(),
  absentAgents: new List(),
  hrEmployees: new List(),
};

const getters = {
  employees: (state) => state.employees,
  managers: (state) => state.managers,
  agents: (state) => state.agents,
  activeAgents: (state) => state.activeAgents,
  absentAgents: (state) => state.absentAgents,
  hrEmployees: (state) => state.hrEmployees,
};

const actions = {
  async getEmployees({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/employees", {
      params: new ListParams(data),
    });
    if (response.status === 200) {
      commit("setEmployees", response.data);
      commit("setLoading", false);
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(
        this.$app.$t("messages.error.internalServerError")
      );
    }
  },
  async getManagers({ commit }) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/employees/managers");
    if (response.status === 200) {
      commit("setManagers", response.data);
      commit("setLoading", false);
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(
        this.$app.$t("messages.error.internalServerError")
      );
    }
  },
  async getAgents({ commit }) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/employees/agents", {
      params: new ListParams(),
    });
    if (response.status === 200) {
      commit("setAgents", response.data);
      commit("setLoading", false);
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(
        this.$app.$t("messages.error.internalServerError")
      );
    }
  },
  async createEmployee({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.post("/employees", data);
    if (response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(
        this.$app.$t("messages.success.created", { entity: "Employee" })
      );
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(
        this.$app.$t("messages.error.internalServerError")
      );
    }
  },
  async fireEmployee({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.delete(`/employees/${data.id}`);
    if (response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(
        this.$app.$t("messages.success.fired", { entity: "Employee" })
      );
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(
        this.$app.$t("messages.error.internalServerError")
      );
    }
  },
  async getActiveAgents({ commit }) {
    commit("setLoading", true); //TODO
    // const response = await this.$app.$http.get('/employees/active_agents', {params: new ListParams()});
    // if (response.status === 200) {
    //   commit('setActiveAgents', response.data);
    //   commit('setLoading', false);
    // } else {
    //   commit('setLoading', false);
    //   this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    // }
  },
  async getAbsentAgents({ commit }) {
    commit("setLoading", true); //TODO
    // const response = await this.$app.$http.get('/employees/absent_agents', {params: new ListParams()});
    // if (response.status === 200) {
    //   commit('setAbsentAgents', response.data);
    //   commit('setLoading', false);
    // } else {
    //   commit('setLoading', false);
    //   this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    // }
  },
  async adjustTeams({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.post(
      "/employees/adjust_teams",
      data
    );
    if (response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(
        this.$app.$t("messages.success.updated", { entity: "Teams" })
      );
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(
        this.$app.$t("messages.error.internalServerError")
      );
    }
  },
  async getHREmployees({ commit }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get(
        "/headcount_employees/hr_employees"
      );
      if (response.status === 200) {
        commit("setHREmployees", response.data);
      } else {
        this.$app.$snotify.error(
          this.$app.$t("messages.error.internalServerError")
        );
      }
    } catch (error) {
      this.$app.$snotify.error(
        this.$app.$t("messages.error.internalServerError")
      );
    } finally {
      commit("setLoading", false);
    }
  },
  async changePassword({ commit }, { oldPassword, newPassword }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.patch(
        "/employees/change_password",
        {
          old_password: oldPassword,
          new_password: newPassword,
        }
      );

      if (response.status === 200) {
        this.$app.$snotify.success("Password changed successfully");
      } else if (response?.status === 400) {
        this.$app.$snotify.error("Current password is incorrect");
      } else {
        this.$app.$snotify.error(
          this.$app.$t("messages.error.internalServerError")
        );
      }
    } catch (error) {
      const msg =
        error.response?.data?.error ||
        this.$app.$t("messages.error.internalServerError");
      this.$app.$snotify.error(msg);
    } finally {
      commit("setLoading", false);
    }
  },
  async grantAccess({ commit }, data) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.post(
        "/employees/give_access",
        data
      );

      if (response?.status && response?.status === 200) {
        return response.data;
      } else if (response?.status === 404) {
        this.$app.$snotify.error(
          "Employee not found in database, please add him first"
        );
      } else {
        this.$app.$snotify.error(
          this.$app.$t("messages.error.internalServerError")
        );
        return null;
      }
    } catch (error) {
      const msg =
        error.response?.data?.error ||
        this.$app.$t("messages.error.internalServerError");
      this.$app.$snotify.error(msg);
      return null;
    } finally {
      commit("setLoading", false);
    }
  },
  async getAccessEmployees({ commit }, data) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get(
        "/employees/access_employees",
        {
          params: data,
        }
      );
      if (response?.status && response?.status === 200) {
        commit("setEmployees", response.data);
      } else {
        this.$app.$snotify.error("Failed to load access employees.");
      }
    } catch (error) {
      this.$app.$snotify.error("Failed to load access employees.");
    } finally {
      commit("setLoading", false);
    }
  },

  async removeEmployee({ commit }, { id }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.delete(`/employees/${id}`);
      if (response?.status === 200) {
        this.$app.$snotify.success("Employee access removed successfully.");
      } else {
        this.$app.$snotify.error("Failed to remove employee access.");
      }
    } catch (error) {
      this.$app.$snotify.error("Failed to remove employee access.");
    } finally {
      commit("setLoading", false);
    }
  },
};

const mutations = {
  setEmployees: (state, data) => {
    state.employees = new List({
      items: data.items.map((employee) => new Employee(employee)),
      count: data.total_count,
    });
  },
  setManagers: (state, data) => {
    state.managers = new List({
      items: data.items.map((employee) => new Employee(employee)),
      count: data.total_count,
    });
  },
  setAgents: (state, data) => {
    state.agents = new List({
      items: data.items.map((employee) => new Employee(employee)),
      count: data.total_count,
    });
  },
  setActiveAgents: (state, data) => {
    state.activeAgents = new List({
      items: data.items.map((employee) => new Employee(employee)),
      count: data.total_count,
    });
  },
  setAbsentAgents: (state, data) => {
    state.absentAgents = new List({
      items: data.items.map((employee) => new Employee(employee)),
      count: data.total_count,
    });
  },
  setHREmployees: (state, data) => {
    state.hrEmployees = new List({
      items: data.items.map((employee) => new Employee(employee)),
      count: data.total_count,
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
