// leave.js - Fully Fixed Version
export class Leave {
  /** @param {Object} leave API response object. */
  constructor(leave = {}) {
    this.id = leave.id || null;
    this.leave_name = leave.name || null; 
    this.yearly_limit = leave.yearly_limit || null; 
    this.monthly_fraction = leave.monthly_fraction || null; 
  }
}
