export class Employee {
  /**
   * @param {Object} employee FastJson object.
   */
  constructor(employee = {}) {
    if (employee.attributes) {
      this.name = employee.attributes.name || null;
      this.RES_ID = employee.attributes.RES_ID || null;
      this.national_ID = employee.attributes.national_ID || null;
      this.team_ID = employee.attributes.team_ID || null;
      this.target_hours_per_day =
        employee.attributes.target_hours_per_day || null;
      this.email = employee.attributes.email || null;
      this.date_joined = employee.attributes.date_joined || null;
      this.manager_ID =
        employee.attributes.manager_ID || "No available manager";
      this.employee_type = employee.attributes.employee_type || null;
      this.hourly_rate =
        employee.attributes.hourly_rate || "Paid Monthly not hourly";
      this.monthly_rate =
        employee.attributes.monthly_rate || "Paid hourly not monthly";
      this.hourly_or_monthly = employee.attributes.hourly_or_monthly
        ? "Monthly"
        : "Hourly";
      this.can_manage = employee.attributes.can_manage || null;
      this.role = employee.attributes.role || null;
      this.base_salary = employee.attributes.base_salary || null;
      this.department_id = employee.attributes.department_id || null;
      this.role_title = employee.attributes.role_title || null;
    } else {
      this.name = employee.name || null;
      this.RES_ID = employee.RES_ID || null;
      this.national_ID = employee.national_ID || null;
      this.team_ID = employee.team_ID || null;
      this.target_hours_per_day = employee.target_hours_per_day || null;
      this.email = employee.email || null;
      this.date_joined = employee.date_joined || null;
      this.manager_ID = employee.manager_ID || "No available manager";
      this.employee_type = employee.employee_type || null;
      this.hourly_rate = employee.hourly_rate || "Paid Monthly not hourly";
      this.monthly_rate = employee.monthly_rate || "Paid hourly not monthly";
      this.hourly_or_monthly = employee.hourly_or_monthly
        ? "Monthly"
        : "Hourly";
      this.can_manage = employee.can_manage || null;
      this.role = employee.role || null;
      this.base_salary = employee.base_salary || null;
      this.department_id = employee.department_id || null;
      this.role_title = employee.role_title || null;
    }
  }
}
