import Vue from "vue";
import VueRouter from "vue-router";
import _ from "lodash";
import store from "../store";

import sessionRoutes from "./session";
import mainRoutes from "./main";

Vue.use(VueRouter);

const $store = store;
const router = new VueRouter({
  mode: "history",
  routes: [...sessionRoutes, ...mainRoutes],
});

router.beforeResolve((_to, _from, next) => {
  next();
});

router.beforeEach((to, _from, next) => {
  if (
    _.isEmpty($store.getters.accessToken) &&
    _.isNil($store.getters.currentUser.RES_ID)
  ) {
    if (to.name === "loginPage") {
      next();
    } else if (to.name === "welcomePage") {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    if (to.name === "loginPage" || to.name === "welcomePage") {
      next({
        path: "/main",
      });
    } else if (
      to.meta.requiresAUDITORAuth &&
      to.meta.requiresHRAuth &&
      to.meta.requiresHEADAuth &&
      to.meta.requiresTLAuth
    ) {
      if (
        JSON.parse(localStorage.currentUserData).role_title?.match(/audit/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(/hr/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(/head/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(
          /team leader/i
        ) ||
        localStorage.head_auditing
      ) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (
      to.meta.requireCEOAuth &&
      to.meta.requiresHRAuth &&
      to.meta.requiresHEADAuth
    ) {
      if (
        JSON.parse(localStorage.currentUserData).role_title?.match(/ceo/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(/hr/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(/head/i)
      ) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (
      to.meta.requireCEOAuth &&
      to.meta.requiresHEADAuth &&
      to.meta.requiresTLAuth &&
      to.meta.requiresAUDITORAuth
    ) {
      if (
        JSON.parse(localStorage.currentUserData).role_title?.match(/ceo/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(/audit/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(/head/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(
          /team leader/i
        ) ||
        localStorage.head_auditing
      ) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (
      to.meta.requireCEOAuth &&
      to.meta.requiresHRAuth &&
      to.meta.requiresAUDITORAuth
    ) {
      if (
        JSON.parse(localStorage.currentUserData).role_title?.match(/ceo/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(/audit/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(/hr/i) ||
        localStorage.head_auditing
      ) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (to.meta.requireCEOAuth && to.meta.requiresHRAuth) {
      if (
        JSON.parse(localStorage.currentUserData).role_title?.match(/ceo/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(/hr/i)
      ) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (to.meta.requireCEOAuth && to.meta.requiresHEADAuth) {
      if (
        JSON.parse(localStorage.currentUserData).role_title?.match(/ceo/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(/head/i)
      ) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (to.meta.requiresCOLDHEADAuth && to.meta.requiresHRAuth) {
      if (
        (parseInt(localStorage.type, 10) === 3 &&
          JSON.parse(localStorage.currentUserData).department_id == 5) ||
        parseInt(localStorage.type, 10) === 2
      ) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (to.meta.requireCEOAuth) {
      if (JSON.parse(localStorage.currentUserData).role_title?.match(/ceo/i)) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (to.meta.requiresHRAuth) {
      if (JSON.parse(localStorage.currentUserData).role_title?.match(/hr/i)) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (to.meta.requiresHEADAuth) {
      if (JSON.parse(localStorage.currentUserData).role_title?.match(/head/i)) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (to.meta.requiresTLAuth) {
      if (
        JSON.parse(localStorage.currentUserData).role_title?.match(
          /team leader/i
        )
      ) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (to.meta.requiresCOLDHEADAuth) {
      if (
        parseInt(localStorage.type, 10) === 3 &&
        JSON.parse(localStorage.currentUserData).department_id == 5
      ) {
        next();
      } else {
        next({
          path: "/main",
        });
      }
    } else if (to.meta.requiresAdminAuth) {
      if (
        JSON.parse(localStorage.currentUserData).role_title?.match(/ceo/i) ||
        JSON.parse(localStorage.currentUserData).role_title?.match(
          /head of hr/i
        )
      ) {
        next();
      } else {
        next({ path: "/main" });
      }
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  setTimeout(() => {
    const contentWrapper = document.querySelector("html");
    if (contentWrapper !== null) {
      contentWrapper.scrollTop = 0;
    }
  }, 200);
});

export default router;
