import { List, ListParams} from "models/list";
import { Department } from "models/department";

const state = {
  departments: new List()
};

const getters = {
  departments: state => state.departments
};

const actions = {
  async getDepartments({ commit }, { payload, trackLoading = true}) {
    if (trackLoading) { commit("setLoading", true); }
    try {
      const response = await this.$app.$http.get("/departments", {params: new ListParams(payload)});
      if (response.status === 200) {
        commit("setDepartments", response.data);
        if (trackLoading) {
          this.$app.$snotify.success("Fetched departments successfully.");
        }
      } else {
        throw new Error("Failed to fetch the departments");
      }
    } catch(error) {
      this.$app.$snotify.error(
        "An error occurred while fetching the departments."
      );
    } finally {
      if (trackLoading) { commit("setLoading", false); }
    }
  },
  async createDepartment({ commit }, departmentName) {
    commit("setLoading", true);
    const payload = {
      department: {
        department_name: departmentName.trim()
      }
    };

    try {
      const response = await this.$app.$http.post("/departments", payload);
      if (response.status === 201) {
        this.$app.$snotify.success("Department created successfully.");
        return true;
      } else {
        throw new Error("Failed to create the new department");
      }
    } catch(error) {
      this.$app.$snotify.error(
        "An error occurred while creating the department."
      );
      return false;
    } finally {
      commit("setLoading", false);
    }
  },
  async updateDepartment({ commit }, { departmentID, newDepartmentName }) {
    commit("setLoading", true);
    const payload = {
      department: {
        department_name: newDepartmentName.trim()
      }
    };

    try {
      const response = await this.$app.$http.patch(`/departments/${departmentID}`, payload);
      if (response.status === 204) {
        this.$app.$snotify.success("Department updated successfully.");
        return true;
      } else {
        throw new Error("Failed to update the department.");
      }
    } catch(error) {
      this.$app.$snotify.error(
        "An error occurred while updating the department."
      );
      return false;
    } finally {
      commit("setLoading", false);
    }
  }
};

const mutations = {
  setDepartments(state, data) {
    state.departments = new List({items: data.items.map((department) => new Department(department)), count: data.total_count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};