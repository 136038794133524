import axios from "axios";
import store from "../store";
import _ from "lodash";

const API_URL = 'https://goldfish-app-o9xk6.ondigitalocean.app';
// const API_URL = 'https://plankton-app-m4zmj.ondigitalocean.app/';
// const API_URL = "http://localhost:3000";
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.accessToken}`,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization =
      localStorage && localStorage.accessToken ? localStorage.accessToken : "";
    if (store.getters.currentPermission) {
      config.headers.permission = store.getters.currentPermission;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (!_.isNil(response)) {
      return response;
    }
  },
  (error) => {
    if (
      _.isNil(error) ||
      _.isNil(error.response) ||
      error.response.status === 500
    ) {
      // window.location = '/network-error';
      return {
        errors: [],
        status: error.response.status,
      };
    } else {
      switch (error.response.status) {
        case 401:
          console.log("401 Unauthorized: Redirecting to login.");
          store.dispatch("resetSession");
          store.commit("clearUserSession"); // Clear session
          if (error.response.data.error !== "Invalid email or password") {
            sessionStorage.setItem(
              "redirectionMessage",
              "Your login session has expired, please login again."
            );
          }
          if (window.location.pathname !== "/login") {
            window.location = "/login";
          }

          break;
        case 403:
          console.log("Redirecting to /forbidden due to:", error.response.data);
          window.location = "/forbidden";
          break;
        default:
          return {
            errors: error.response.data,
            status: error.response.status,
          };
      }
    }
  }
);

export default axiosInstance;
