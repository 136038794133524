import { List, ListParams } from "models/list";
import { LeaveRequest } from "models/leaveRequest";

const state = {
  leaveRequests: new List(),
  selectedLeaveRequest: null,
  leavesNotification: null,
  leavesImportError: null,
};

const getters = {
  leaveRequests: (state) => state.leaveRequests,
  selectedLeaveRequest: (state) => state.selectedLeaveRequest,
  leavesNotification: (state) => state.leavesNotification,
  leavesImportError: (state) => state.leavesImportError,
};

const actions = {
  async getLeaveRequests({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/leave_requests", {
      params: new ListParams(data),
    });
    if (response.status === 200) {
      commit("setLeaveRequests", response.data);
      commit("setLoading", false);
      this.$app.$snotify.success(
        this.$app.$t("messages.success.fetched", {
          entity: this.$app.$tc(
            "models.leaveRequest.entity",
            response.data.total_count
          ),
        })
      );
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(
        this.$app.$t("messages.error.internalServerError")
      );
    }
  },
  async getLeaveRequestDetails({ commit }, { id }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get(`/leave_requests/${id}`);
      if (response.status === 200) {
        const { leave_request, ...rest } = response.data;
        const flattenedLeaveRequest = { ...leave_request, ...rest };

        const leaveRequestObj = new LeaveRequest(flattenedLeaveRequest);
        commit("setSelectedLeaveRequest", leaveRequestObj);
      }
    } catch (error) {
      console.error("Error fetching leave request details:", error);
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
  async createLeaveRequest({ commit }, FormData) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.post("/leave_requests", FormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (
        response.errors &&
        response.errors.errors[0] ===
          "Overlapping leave request exists for this period"
      ) {
        this.$app.$snotify.error(
          "Employee already has a leave request overlapping this period."
        );
      }

      if (response.status === 201) {
        this.$app.$snotify.success(
          "Leave Request has been added successfully."
        );
        return true;
      }
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;

        if (
          errors.includes("Overlapping leave request exists for this period")
        ) {
          this.$app.$snotify.error(
            "Employee already has a leave request overlapping this period."
          );
        } else {
          this.$app.$snotify.error(errors.join(", "));
        }
      } else {
        this.$app.$snotify.error(
          "An error occurred while adding the leave request."
        );
      }
    } finally {
      console.log("finally");

      commit("setLoading", false);
    }
  },
  async updateLeaveRequest({ commit }, { id, updatedFields }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.put(
        `/leave_requests/${id}`,
        updatedFields,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        this.$app.$snotify.success(
          "Leave request has been successfully updated."
        );
        return response.data;
      } else {
        this.$app.$snotify.error("Failed to update leave request.");
      }
    } catch (error) {
      console.error("Error updating leave request:", error);
      this.$app.$snotify.error("Error updating leave request.");
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
  async approveLeaveRequest({ commit }, { leaveRequestId, comment }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.post(
        `/leave_requests/${leaveRequestId}/approve`,
        {
          reviewer_comments: comment,
        }
      );
      if (response.status === 200) {
        this.$app.$snotify.success("Leave request approved successfully.");
        commit("setSelectedLeaveRequest", response.data);
      }
    } catch (error) {
      console.error("Error approving leave request:", error);
      this.$app.$snotify.error("Error approving leave request.");
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
  async rejectLeaveRequest({ commit }, { leaveRequestId, comment }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.post(
        `/leave_requests/${leaveRequestId}/reject`,
        {
          reviewer_comments: comment,
        }
      );
      if (response.status === 200) {
        this.$app.$snotify.success("Leave request rejected successfully.");
        commit("setSelectedLeaveRequest", response.data);
      }
    } catch (error) {
      console.error("Error rejecting leave request:", error);
      this.$app.$snotify.error("Error rejecting leave request.");
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
  async uploadLeavesCSV({ commit }, file) {
    commit("setLoading", true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await this.$app.$http.post(
        "/leave_requests/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      commit("setLeavesNotification", {
        type: "success",
        message: `Processed ${response.data.total} records, successfully created ${response.data.successful} leave requests.`,
      });
      commit("setLeavesImportError", response.data.errors);
      return response.data;
    } catch (error) {
      commit("setLeavesNotification", {
        type: "error",
        message: "Failed to upload the CSV file. Please try again.",
      });
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
  async deleteLeaveRequest({ commit }, leaveRequestId) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.delete(
        `/leave_requests/${leaveRequestId}`
      );
      if (response.status === 200) {
        this.$app.$snotify.success("Leave request deleted successfully.");

        // Optionally remove it from the store immediately:
        commit("removeLeaveRequestFromList", leaveRequestId);
      } else {
        // If the server doesn't return 200, handle it
        this.$app.$snotify.error("Failed to delete leave request.");
      }
    } catch (error) {
      console.error("Error deleting leave request:", error);
      this.$app.$snotify.error(
        "An error occurred while deleting the leave request."
      );
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
};

const mutations = {
  setLeaveRequests: (state, data) => {
    state.leaveRequests = new List({
      items: data.items.map((leaveRequest) => new LeaveRequest(leaveRequest)),
      count: data.total_count,
    });
  },
  setSelectedLeaveRequest: (state, leaveRequest) => {
    state.selectedLeaveRequest = leaveRequest;
  },
  setLeavesNotification: (state, leavesNotification) => {
    state.leavesNotification = leavesNotification;
  },
  setLeavesImportError: (state, leavesImportError) => {
    state.leavesImportError = leavesImportError;
  },
  removeLeaveRequestFromList(state, leaveRequestId) {
    const newItems = state.leaveRequests.items.filter(
      (lr) => lr.id !== leaveRequestId
    );
    state.leaveRequests = new List({
      items: newItems,
      count: newItems.length,
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
