import moment from "moment";
export class LeaveRequest {
  /**
   * @param {Object} leaverequest FastJson object.
   */
  constructor(leaverequest = {}) {
    this.id = leaverequest.id || null;
    this.employee_id = leaverequest.employee_id || null;
    this.employee_name = leaverequest.employee_name || null;
    this.leave_type_name = leaverequest.leave_type_name || null;
    this.leave_type_id = leaverequest.leave_type.id || null;
    this.start_date = leaverequest.start_date || null;
    this.end_date = leaverequest.end_date || null;
    this.status = leaverequest.status || "pending";
    this.notes = leaverequest.notes || null;
    this.submitted_by_id = leaverequest.submitted_by_id || null;
    this.submitted_by_name = leaverequest.submitted_by_name || null;
    this.manager_email = leaverequest.manager_email || null;
    this.reviewed_by_id = leaverequest.reviewed_by_id || null;
    this.reviewed_by_name = leaverequest.reviewed_by_name || null;

    this.reviewer_comments = leaverequest.reviewer_comments || null;
    this.created_at = leaverequest.created_at || null;
    this.updated_at = leaverequest.updated_at || null;
    this.sick_note_url = leaverequest.sick_note_url || null;
    this.leave_duration = leaverequest.leave_duration || null;

    this.formatted_created_at = this.formatDate(this.created_at);
    this.formatted_updated_at = this.formatDate(this.updated_at);
    this.formatted_start_date = this.formatDate(this.start_date);
    this.formatted_end_date = this.formatDate(this.end_date);

    // HR-Specific Fields
    this.worked_days_this_year = leaverequest.worked_days_this_year ?? null;
    this.passed_probation = leaverequest.passed_probation ?? null;

    this.leave_balances = (leaverequest.leave_balances || []).map(
      (balance) => new LeaveBalance(balance)
    );
  }

  formatDate(dateString) {
    return dateString ? moment(dateString).format("DD/MM/YYYY") : null;
  }
}

export class LeaveBalance {
  constructor(leaveBalance = {}) {
    this.leave_type = leaveBalance.leave_type || null;
    this.remaining_balance = leaveBalance.remaining_balance ?? null;
    this.approved_leaves_this_year =
      leaveBalance.approved_leaves_this_year ?? null;
  }
}
